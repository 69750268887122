@import "../../variables";

.detailedInformation{
    display: flex;
    padding: 16px;
    margin: 16px 0;

    max-width: 400px;
    border-radius: 6px;
    cursor: pointer;

    .icon{
        margin-right: 32px;
    }

    .information{
        >*{
            display: block;
        }

        .title{
            font-weight: bold;
            margin-bottom: 8px;
        }
    }

    &.isActive{
        box-shadow: 0 23px 43px rgba(0, 0, 0, 0.1);
        cursor: default;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        max-width: 360px;
        margin: 16px 16px 0 0;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        margin: 16px 0;
    }
}
