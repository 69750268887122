
@import "../../variables";

.footer{
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;

    $paddingSize: 32px;

    a{
        color: white;
        font-weight: 600;
        text-decoration: none;
    }

    .logo, .address, .links, .icons, .compliance{
        padding-top: $paddingSize;
    }

    .logo img{
        width: 200px;
    }

    .address, .document{
        color: #bbb;
        line-height: 1.5em;
    }

    .links{
        padding-top: 32px;

        :not(:first-child){
            padding-left: 24px;
        }
    }

    .icons > *{
        padding: 0 8px;
    }

    .compliance{
        padding-bottom: $paddingSize;
        color: #bbbbbb;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        text-align: center;
        padding: $paddingTopMobile $paddingMobile;
    }
}