
@import "../../variables";

.mission{
    padding: $paddingTop $padding;
    display: flex;

    >*{
        width: 50%;
    }

    .texts{
        padding-right: 56px;

        h2{
            padding: 16px 0;

            &:nth-child(1){
                padding-top: 0;
            }
        }
    }

    .images{
        padding-left: 56px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        > *{
            display: flex;
            justify-content: space-around;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        padding: $paddingTopMobile $paddingMobile;
        flex-wrap: wrap;

        >*{
            width: 100%;
        }

        .texts{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 329px;
            align-content: space-between;
            padding-right: 8px;

            >*{
                width: 50%;
                padding-right: 16px;
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .texts{
            max-height: unset;
            padding-right: 0;

            >*{
                width: 100%;
                padding-right: 0;
            }
        }

        .images {
            padding-left: 0;
            > *{
                flex-wrap: wrap;
            }
        }
    }
}