@import "../../variables";

.doubleIcon {
    position: relative;
    $iconSize: 40px;

    .primaryIcon {
        background-color: $primaryColor;

        height: $iconSize;
        width: $iconSize;

        border-radius: 50%;
        img {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
        }
    }

    .secondaryIcon{
        position: absolute;
        top: calc(100% - #{$iconSize / 4});
        left:  calc(100% - #{$iconSize / 4});

        img{
            $size: 20px;
            height: $size;
            width: $size;
        }
    }
}