@import "../../variables";

.downloadTheApp {
    padding: $paddingTop 0 $paddingTop $padding;
    display: flex;
    justify-content: space-between;

    h2 {
        margin: 8px 0;
    }

    h3 {
        margin: 0;
    }

    $radius: 16px;

    @mixin baseBackground($color){
        content: '';
        background-color: $color;
        position: absolute;
        right: 0;
        z-index: -1;

        border-bottom-left-radius: $radius;
        border-top-left-radius: $radius;
    }

    .downloadTheAppCallToAction{
        .content{
            position: relative;
            $height: 330px;

            min-height: $height;

            &:before{
                @include baseBackground($primaryColor);

                height: 210px;
                width: 105%;
                top: 25%;
            }

            .image{
                margin-right: $padding;
                height: $height;
            }

            .qrCode{
                position: absolute;
                top: 25%;
                left: 52.5%;
                transform: translate(-50%, 25px);
                height: 160px;
            }
        }

        .callToAction{
            padding: 8px 0;
            position: relative;

            &:before{
                @include baseBackground($primaryColor);

                height: 100%;
                width: 115%;
                top: 0;
            }

            .actions{
                padding: 8px 0;
                margin-right: $padding;

                img{
                    width: 120px;
                    cursor: pointer;
                    &:not(:first-child){
                        margin-left: 8px;
                    }
                }
            }
        }

        .scanQrCode{
            @include baseBackground(#000);
            color: $primaryColor;
            font-weight: bold;

            padding: 16px 16px 16px 32px;
        }

        @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
            padding: $paddingTopMobile 0 $paddingTopMobile $paddingMobile;

            .callToAction{
                .actions{
                    display: flex;
                    justify-content: space-around;
                    margin-right: 16px;
                }
            }
        }

        @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
            padding: 0;

            @mixin backgroundMobile{
                border-radius: $radius;
                left: 0;
                width: 100%;
            }

            .scanQrCode{
                text-align: center;
                position: relative;
                border-radius: $radius;
                padding: 16px 0;
                right: unset;
            }

            .content {
                text-align: center;
                &:before{
                    @include backgroundMobile;
                }

                .image{
                    margin-right: 0;
                }
            }

            .callToAction{
                text-align: center;

                &:before{
                    @include backgroundMobile;
                }

                .actions{
                    margin: 0 16px;
                    padding: 8px 0 0 0;
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        padding: $paddingTopMobile 0 $paddingTopMobile $paddingMobile;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        display: block;
        justify-content: unset;
        padding: $paddingTopMobile $paddingMobile;
    }
}