
@import "../../variables.scss";

.cover {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('/assets/images/cover.png');
        width: 100%;
        height: 100%;
        filter: brightness(.5);
        z-index: -1;
        background-size: cover;
    }

    .header, .body {
        padding: $paddingTop $padding;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 150px;
        }

        .menu {
            display: flex;
            align-items: center;


            a {
                padding: 8px 16px;
                font-weight: bold;

                background: $primaryColor;
                text-decoration: none;
                color: black;

                transition: background-color .3s;
                $radius: 4px;

                &:first-child {
                    border-top-left-radius: $radius;
                    border-bottom-left-radius: $radius;
                }

                &:last-child {
                    border-top-right-radius: $radius;
                    border-bottom-right-radius: $radius;
                }

                &:hover {
                    background-color: $primaryColorDarker;
                }
            }
        }

        .signIn a {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                margin-left: 8px;
                color: white;
            }
        }
    }

    .body {
        display: flex;
        margin-bottom: 72px;

        .singUp {
            width: 480px;

            .title {
                color: white;
                font-size: 45px;

                .grootbit {
                    font-size: 90px;
                    font-weight: bold;
                }
            }

            p {
                color: white;
                font-size: 18px;
                margin-bottom: 64px;
            }
        }

        .image {
            position: absolute;
            right: 0;
            bottom: 72px;
            z-index: 5;

            img {
                max-width: 35vw;
            }
        }
    }

    .footer img {
        width: 100%;
        position: relative;
        bottom: -6px;
    }

    @media only screen and (max-width: $tabletWidth), (-webkit-min-device-pixel-ratio: 3) {
        .header, .body {
            padding: $paddingTopMobile $paddingMobile;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .header {
            .logo {
                width: 100px;
            }

            .menu {
                display: none;
            }
        }

        .body .image {
            bottom: 0;

            img {
                max-width: 60vw;
            }
        }
    }
}