@import "../../variables";

.quotes{
    margin: 0 $padding;

    display: flex;
    justify-content: space-between;

    padding: 16px 32px;
    border-radius: 4px;
    box-shadow: 27px 29px 67px rgba(81, 68, 17, 0.09);
    min-width: calc(100% - #{$padding * 2});

    overflow: auto;

    .quoteItem{
        display: flex;
        flex-direction: column;
        padding-right: 16px;
        min-width: 180px;

        .labels{
            display: flex;
            padding-bottom: 16px;

            .icon{
                $size: 30px;
                height: $size;
                width: $size;
                padding: 4px;
                border-radius: 8px;

                img{
                    width: auto;
                    height: 25px;

                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .infos{
                display: flex;
                flex-direction: column;
                padding-left: 8px;

                .coinInitialsAndRate{
                    .initials{
                        font-weight: 600;
                        font-size: 12px;
                        margin-right: 8px;
                    }

                    .rate{
                        border-radius: 24px;
                        color: white;
                        padding: 2px 8px;

                        font-size: 11px;
                        font-weight: 100;

                        &:before{
                            font-weight: bold;
                        }
                    }

                    &.positive{
                        $color: green;

                        .initials{
                            color: $color;
                        }

                        .rate{
                            background-color: green;

                            &:before{
                                content: '\2191';
                            }
                        }
                    }

                    &.negative{
                        $color: red;

                        .initials{
                            color: $color;
                        }

                        .rate{
                            background-color: red;

                            &:before{
                                content: '\2193';
                            }
                        }
                    }
                }

                .labelCoin{
                    color: #666;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }

        .price{
            font-size: 14px;
            font-weight: bold;
            color: #333333;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        margin: 0 $paddingMobile;
        padding: 16px;
    }
}