.cardImage{
    margin: 16px;
    padding: 16px;

    box-shadow: 0 23px 43px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .title{
        text-align: center;
        margin: 16px;
        font-weight: 600;
        font-size: .9em;
    }
}