
@import "../../variables";

.faq {
    $spacePadding: $paddingTop;

    display: flex;
    flex-wrap: wrap;
    margin: $spacePadding 0;
    position: relative;

    .title {
        width: 100%;

        span {
            background-color: #014F27;
            font-size: 40px;
            font-weight: bold;
            color: white;
            padding: 16px #{$spacePadding * 3} 16px $padding;
            display: inline-block;

        }
    }

    .menu, .information {
        width: calc(50% - 8px);
    }

    .menu {
        padding-left: $padding;
        margin-top: 4px;

        .menuItem {
            display: flex;
            justify-content: space-between;

            padding: 16px;
            cursor: pointer;
            border-radius: 4px;
            max-width: 360px;

            &.active {
                background-color: $primaryColor;
                font-weight: bold;
                cursor: default;

                &::after {
                    content: '>';
                    font-weight: bold;
                }
            }

            &:not(.active):hover {
                background-color: $primaryColorLighterTransparent;
            }
        }
    }

    .information {
        padding: 24px 0;
        max-width: 400px;
    }

    .dummyLineOverhead, .dummyLineVertical, .dummyLineBottom {
        background-color: $primaryColorTransparent;
        position: absolute;
    }

    $lineThickness: 4px;
    $marginTop: $spacePadding * -1 + 10px;
    $widthStart: 40%;

    .dummyLineOverhead {
        width: $widthStart;
        height: $lineThickness;
        top: $marginTop;
    }

    .dummyLineVertical {
        height: calc(100% + #{$marginTop * -1});
        width: $lineThickness;
        top: $marginTop;
        left: $widthStart;
    }

    .dummyLineBottom {
        width: 100% - $widthStart;
        height: $lineThickness;
        top: 100%;
        left: $widthStart;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        justify-content: space-between;

        padding-right: $paddingMobile;

        .menu, .information {
            width: 45%;
        }

        .information {
            padding-top: 8px;
        }

        .title span {
            padding-left: $paddingMobile;
        }

        .menu {
            padding-left: $paddingMobile;

            .menuItem {
                max-width: 300px;
            }
        }

        .dummyLineOverhead {
            display: none;
        }

        .dummyLineVertical {
            display: none;
        }

        .dummyLineBottom {
            display: none;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        .title span {
            text-align: center;
            width: calc(100% - #{$paddingMobile});
            padding: $paddingTopMobile $paddingMobile;
        }
    }
}