body{
    margin: 0;
}

.icon img{
    width: 24px;

    &:hover{
        filter: invert(.35);
    }
}

@font-face {
    font-family: "Century Gothic";
    font-weight: 400;
    src: local('Century-Gothic'), url('assets/fonts/Century-Gothic.otf') format('opentype');
}

@font-face {
    font-family: "Century Gothic";
    font-weight: 700;
    src: local('Century-Gothic'), url('assets/fonts/Century-Gothic-Bold.otf') format('opentype');
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Century Gothic", sans-serif;
    font-weight: 600;
    margin: 0;
    color: var(--color-title);
}

span,
div,
a,
p {
    font-family: "Century Gothic", sans-serif;
    font-weight: 400;
    margin: 0;
    color: var(--color-title);
}