$iconSize: 30px;

$tabletWidth: 768px;
$mobileWidth: 425px;

$padding: 10vw;
$paddingTop: 56px;
$paddingMobile: 5vw;
$paddingTopMobile: 32px;

$primaryColor: #FCCD1A;
$primaryColorDarker: #dab31f;
$primaryColorTransparent: rgba(252, 205, 26, .55);
$primaryColorLighterTransparent: rgba(252, 205, 26, .1);

